import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { httpGet } from '../../services/http'
import './style.less'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Pagewrapper from '../../components/PageWrapper'

export default function Step7Page() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "bl-report.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    useEffect(() => {
        httpGet('/landing/article/guide-step-7/read')
    }, [])

    return (
        <Pagewrapper>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0.88,1)',
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="calendar example"
                        imgStyle={{objectFit: 'contain'}}
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="Double check your Compliance Report before submitting to the authorities…"
                subTitle={
                    <div style={{ maxWidth: 500, margin: 'auto', textAlign: 'left' }}>
                        <ul>
                            <li>Check your CPR-number</li>
                            <li>
                                Check if your exemption period is defined correctly.
                                (Start/Slut-dato for udlandsophold, must be on days were you can
                                document that you left/arrived in Denmark)
                            </li>
                            <li>
                                Check if all your intended notes shows in the “Bemærkninger” column.
                                (Required to be ticked on to show on report)
                            </li>
                        </ul>
                    </div>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/step6/">
                        <Button>back</Button>
                    </Link>,
                    <Link to="/onboarding/guide-complete/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </Pagewrapper>
    )
}
